import anime from 'animejs/lib/anime.es.js';

document.addEventListener('DOMContentLoaded', () => {
    homeTopAnimation();
});

document.addEventListener('resize', () => {
    homeTopAnimation();
});

const homeTopAnimation = () => {
    const wrapper = document.getElementById('home_top');
    const image = document.getElementById('home_top_img');

    const start = window.innerHeight - image.offsetHeight / 2;
    const end = wrapper.offsetHeight - start - image.offsetHeight;

    const scrollPercent = () => {
        return window.scrollY / (wrapper.offsetHeight - window.innerHeight) * 100;
    }

    const animation = anime({
        targets: image,
        translateY: [start, end],
        delay: function(el, i) { return i * 100; },
        elasticity: 200,
        easing: 'easeInOutSine',
        autoplay: false,
    });

    document.addEventListener('scroll', () => {
        animation.seek((scrollPercent() / 100) * animation.duration);
    });
}